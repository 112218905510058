<template>
  <div class="project-list">
    <el-dialog
      title="查看计划"
      :visible.sync="dialogVisible"
      width="1200px"
    >
      <vm-table
        ref="vmTable"
        :filter.sync="filter"
        url="/maintenance/maintain-plan/page"
        @selection-change="handleSelectionChange">
        <template slot="adSearch">
          <div class="vm-search">
            <vm-search label="楼盘">
              <vm-autocomplete
                :filter.sync="filter.realEstateName"
                url="real-estate/list"></vm-autocomplete>
            </vm-search>
            <vm-search label="楼宇">
              <vm-autocomplete
                :filter.sync="filter.buildingName"
                url="real-estate/building/list"></vm-autocomplete>
            </vm-search>
            <vm-search :label="$l('project.name','维保班组')" prop="teamName">
              <vm-autocomplete
                :filter.sync="filter.teamName"

                url="organization/team/list"></vm-autocomplete>
            </vm-search>
            <vm-search label="队长姓名">
              <el-input v-model="filter.teamLeaderName"></el-input>
            </vm-search>
            <vm-search label="队长电话">
              <el-input v-model="filter.teamLeaderPhone"></el-input>
            </vm-search>
            <vm-search label="保养类型">
              <vm-dict-select v-model="filter.type" type="maintainType"></vm-dict-select>
            </vm-search>
            <vm-search label="计划保养日期">
              <el-date-picker
                v-model="filter.pdTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleTime">
              </el-date-picker>
            </vm-search>
            <vm-search label="实际保养日期">
              <el-date-picker
                v-model="filter.adTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleTime">
              </el-date-picker>
            </vm-search>
            <vm-search label="注册代码">
              <el-input v-model="filter.regCode"></el-input>
            </vm-search>
            <vm-search label="品牌">
              <vm-autocomplete
                :filter.sync="filter.brandName"
                url="basic/elevator-brand/list"></vm-autocomplete>
            </vm-search>
            <vm-search label="型号">
              <vm-autocomplete
                :filter.sync="filter.modelName"
                url="basic/elevator-model/list"></vm-autocomplete>
            </vm-search>
            <vm-search label="使用单位">
              <el-input v-model="filter.customerCompanyName" type="elevatorUseStatus"></el-input>
            </vm-search>
            <vm-search label="客户姓名">
              <el-input v-model="filter.factoryCode" type="elevatorUseStatus"></el-input>
            </vm-search>
            <vm-search label="客户电话">
              <el-input v-model="filter.factoryCode" type="elevatorUseStatus"></el-input>
            </vm-search>
            <vm-search label="是否完成">
              <el-select v-model="filter.completed">
                <el-option :value="true" label="是"></el-option>
                <el-option :value="false" label="否"></el-option>
              </el-select>
            </vm-search>
          </div>
        </template>

        <!--      <el-table-column align="center" type="index" width="50"></el-table-column>-->
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column prop="teamName" :label="$l('project.name','维保班组')">
          <el-table-column prop="teamLeaderName" label="队长">
            <template slot-scope="scope">
              <div>
                {{scope.row.teamName}}
              </div>
              <div> {{scope.row.teamLeaderName}}/{{scope.row.teamLeaderPhone}}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="realEstateName" label="楼盘/楼宇" align="center">
          <template slot-scope="scope">
            {{scope.row.realEstateName}}/{{scope.row.buildingName}}
          </template>
        </el-table-column>
        <el-table-column prop="typeDesc" label="保养类型">
        </el-table-column>
        <el-table-column prop="planDate" label="计划保养日期">
          <template slot-scope="scope">
            <span></span>
            {{scope.row.planDate?$moment(scope.row.planDate).format("YYYY-MM-DD"):""}}
          </template>
        </el-table-column>
        <el-table-column prop="regCode" label="注册代码" align="center"></el-table-column>
        <el-table-column prop="brandName" label="品牌/型号" align="center">
          <template slot-scope="scope">
            {{scope.row.brandName}}/{{scope.row.modelName}}
          </template>
        </el-table-column>
        <el-table-column prop="customerCompanyName" label="使用单位" align="center"></el-table-column>
        <el-table-column prop="contactName" label="客户姓名/电话" align="center">
          <template slot-scope="scope">
            {{scope.row.contactName}}/{{scope.row.contactPhone}}
          </template>
        </el-table-column>
        <el-table-column prop="actualDate" label="实际保养日期" align="center"></el-table-column>
        <el-table-column prop="completed" label="是否完成" align="center">
          <template slot-scope="scope">
            {{scope.row.completed?"是":"否"}}
          </template>
        </el-table-column>
      </vm-table>
    </el-dialog>
  </div>
</template>
<script>
  import VmAutocomplete from "@/components/VmAutocomplete";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";

  export default {
    components: {VmAutocomplete},
    props:{
      activeName:String,
    },
    data() {
      return {
        filter: {
          planDateFrom:"",
          maintenanceType:"as_need",
          companyId: loginUtil.getCompanyCode(),
        },
        dialogVisible:false,
      };
    },
    mounted() {

    },
    methods: {
      show(date) {
        this.dialogVisible=true;
        this.filter={
          planDateFrom:moment(date).format("YYYY-MM-DD HH:mm:ss"),
          planDateTo:moment(date).add(+1,"days").format("YYYY-MM-DD HH:mm:ss"),
          maintenanceType:"as_need",
          companyId: loginUtil.getCompanyCode(),
        };
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      handleSelectionChange(val) {
        this.selectList = val;
      },
      handleTime() {
        if(this.filter.pdTime !=null) {
          this.filter.planDateFrom = this.filter.pdTime[0];
          this.filter.planDateTo = this.filter.pdTime[1];
        }
        if(this.filter.adTime !=null) {
          this.filter.actualDateFrom = this.filter.adTime[0];
          this.filter.actualDateTo = this.filter.adTime[1];
        }
      },

      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible = false;
      },
      handleSeniorClick() {
        this.senior=!this.senior;
      },


      changeMission(row) {
        console.log(row,122);
        this.$emit("changeTab",row);
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
